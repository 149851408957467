body { background-color: $COL10; }
body { color: $COL11; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL14;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
@media #{$large-up} {
font-size: 25px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL16}
a:hover {color: $COL17}
/* Dark:2 */
.MES2 {
background-color: $COL2;
color: $COL3;
 }
/* Dark:3 */
.MES3 {
background-color: $COL2;
color: $COL3;
 }
.MES3 {
background-color: $COL2;
color: $COL3;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL3;
 }
 }
a.MEC3 { color: $COL4;
 }
cite.MEC3{
color: $COL3;
}
/* Positions:4 */
.MES4 {
background-color: $COL5;
padding: 5px;

 }
.MES4 {
background-color: $COL5;
padding: 5px;

 }
/* Primary:5 */
.MES5 {
background-color: $COL6;
color: $COL8;
 }
/* Primary:6 */
.MES6 {
background-color: $COL6;
color: $COL8;
 }
.MES6 {
background-color: $COL6;
color: $COL8;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL8;
 }
 }
cite.MEC6{
color: $COL8;
}
/* Secondary:7 */
.MES7 {
background-color: $COL7;
color: $COL9;
 }
/* Secondary:8 */
.MES8 {
background-color: $COL7;
color: $COL9;
 }
.MES8 {
background-color: $COL7;
color: $COL9;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL9;
 }
 }
cite.MEC8{
color: $COL9;
}
/* Home Block Panel:9 */
.MES9 {
background-color: $COL12;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://vmcc.com.au/img/57/37');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL4;
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 300px;};
padding: 20px;

 }
.MES9 {
background-color: $COL12;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://vmcc.com.au/img/57/37');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL4;
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 300px;};
padding: 20px;

h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL4;
 }
 }
cite.MEC9{
color: $COL4;
}
/* Hollow:10 */
.MES10 {
color: $COL4;
&:hover { color: $COL4;}
border-radius: 3px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL4;
&:hover { border-color: $COL4; }
 }
/* Grey Panel:11 */
.MES11 {
background-color: $COL5;
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 300px;};
 }
.MES11 {
background-color: $COL5;
min-height:100px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 300px;};
 }
/* Event Panel:12 */
.MES12 {
background-color: $COL12;
& > .underlay, & > .inner-overlay { opacity: 0.35 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://vmcc.com.au/img/49/9');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL4;
padding: 15px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 40px 15px;

}
 }
.MES12 {
background-color: $COL12;
& > .underlay, & > .inner-overlay { opacity: 0.35 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://vmcc.com.au/img/49/9');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL4;
padding: 15px;

@media #{$medium-up} {
padding: 20px 15px;

}
@media #{$large-up} {
padding: 40px 15px;

}
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL4;
 }
 }
cite.MEC12{
color: $COL4;
}
/* Black Pane;:13 */
.MES13 {
background-color: $COL12;
 }
.MES13 {
background-color: $COL12;
 }
/* Alternate:14 */
.MES14 {
background-color: $COL13;
 }
/* Alternate:15 */
.MES15 {
background-color: $COL13;
 }
.MES15 {
background-color: $COL13;
 }
/* Main Menu:16 */
nav.me-Menu.MES16 {
& .menu-item.MEC16, & .menu-item.MEC16 > div.MEC16{ & > a.MEC16{color: $COL13;
text-transform: uppercase;
}
 &:hover > a.MEC16{color: $COL6;
}
 }
&.horizontal > .menu-item.MEC16 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC16 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC16 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC16 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 20px 15px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC16:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL7;}}
&.vertical .menu-item.MEC16:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL7;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC16{background-color: $COL7;
 &:hover {background-color: $COL13}
}
& .menu-item.MEC16, & .menu-item.MEC16 > div.MEC16{ & > a.MEC16{color: $COL2;
}
 &:hover > a.MEC16{color: $COL4;
}
 }

}
}
 }
/* Footer Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: $COL4;
}
 &:hover > a.MEC17{color: $COL4;
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Light:30 */
.MES30 {
background-color: $COL15;
 }
/* White Border Panel:18 */
.MES18 {
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: $COL4;
 }
.MES18 {
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: $COL4;
 }
/* Carousel Top Padding:19 */
.MES19 {
padding: 24px 0 0 0;

 }
.MES19 {
padding: 24px 0 0 0;

 }
/* Slider Panel:20 */
.MES20 {
padding: 15px;

@media #{$medium-up} {
padding: 15px 60px;

}
 }
.MES20 {
padding: 15px;

@media #{$medium-up} {
padding: 15px 60px;

}
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL4;
 }
 }
/* Home Slider:21 */
.MES21 {
& .slider-arrow {color: $COL4;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL7;
} }
/* Carousel:22 */
.MES22 {
& .slider-arrow {color: $COL4;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL7;
} }
/* News Panel:23 */
.MES23 {
background-color: $COL12;
color: $COL4;
 }
.MES23 {
background-color: $COL12;
color: $COL4;
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL4;
 }
 }
cite.MEC23{
color: $COL4;
}
/* News Category:24 */
.MES24 {
padding: 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL5 transparent transparent transparent;}
& > hr {border-top-style: dashed;}
 }
/* News Category Panel:25 */
.MES25 {
 }
.MES25 {
 }
a.MEC25 { color: $COL2;
&:hover { color: $COL2; }
 }
/* Hollow Black:26 */
.MES26 {
color: $COL2;
&:hover { color: $COL2;}
border-radius: 3px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL2;
&:hover { border-color: $COL2; }
 }
/* Responsive Menu:27 */
nav.responsive-menu {
.menu-item.MEC27{background-color: $COL6;
}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: $COL4;
text-align: left;
}
  }
& .menu-item.MEC27 { border:0;
border-color: $COL4;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC27{background-color: $COL13;
}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: $COL12;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC27{background-color: $COL7;
}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: $COL12;
text-align: left;
}
  }
}}

 }
/* Contacts:28 */
details.MES28 {
& > summary{background-color: $COL6;
}
& > summary{padding: 10px;}

& > summary{border-width: 1px;
border-style:solid;
border-color: $COL13;
}& > summary {& > div  > i{color: $COL4;
font-size: 14px;
}}
& > summary { color: $COL4;
text-transform: uppercase
 }
 }
/* Heading Separator:29 */
.MES29 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL13 transparent transparent transparent;}
& > hr {border-top-style: dashed;}
 }
/* Light:31 */
.MES31 {
background-color: $COL15;
 }
.MES31 {
background-color: $COL15;
 }
/* Sub Menu:32 */
nav.me-Menu.MES32 {
& .menu-item.MEC32, & .menu-item.MEC32 > div.MEC32{ & > a.MEC32{color: $COL2;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 21px;
}text-transform: uppercase;
}
 &:hover > a.MEC32{color: $COL6;
}
 }
&.horizontal > .menu-item.MEC32 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC32 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC32 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC32 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 20px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC32.active { & > a{ color: $COL13;}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* News Date:33 */
.MES33 {
background-color: $COL15;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 13.6px;
};
padding: 7.5px 30px;

 }
.MES33 {
background-color: $COL15;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 13.6px;
};
padding: 7.5px 30px;

 }
cite.MEC33{
font-size: 11.9px;
@media #{$medium-up} {
font-size: 13.6px;
};
}
/* Transparent:35 */
.MES35 {
background-color: $COL18;
color: $COL4;
 }
.MES35 {
background-color: $COL18;
color: $COL4;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: $COL4;
 }
 }
cite.MEC35{
color: $COL4;
}
/* Facebook:36 */
.MES36 {
background-color: $COL19;
color: $COL4;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 19.2px;
};
border-radius: 8px;
 }
/* Dots:37 */
.MES37 {
 }
