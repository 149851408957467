$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #333333; //
$COLFLAT2: #333333; //
$COL3: #ffffff; //
$COLFLAT3: #ffffff; //
$COL4: #ffffff; //White
$COLFLAT4: #ffffff; //White
$COL5: #eeeeee; //Light Grey
$COLFLAT5: #eeeeee; //Light Grey
$COL6: #6a6b4b; //
$COLFLAT6: #6a6b4b; //
$COL7: #fcfad4; //
$COLFLAT7: #fcfad4; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #000000; //
$COLFLAT9: #000000; //
$COL10: rgba(0,0,0,0); //
$COLFLAT10: #808080; //
$COL11: #000000; //
$COLFLAT11: #000000; //
$COL12: #000000; //black
$COLFLAT12: #000000; //black
$COL13: #a6a693; //
$COLFLAT13: #a6a693; //
$COL14: #6a6b4b; //
$COLFLAT14: #6a6b4b; //
$COL15: #eeeeee; //
$COLFLAT15: #eeeeee; //
$COL16: #6a6b4b; //
$COLFLAT16: #6a6b4b; //
$COL17: #a6a693; //
$COLFLAT17: #a6a693; //
$COL18: rgba(0,0,0,0.6); //dark trans
$COLFLAT18: #333333; //dark trans
$COL19: #3b5998; //Facebook Blue
$COLFLAT19: #3b5998; //Facebook Blue
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
